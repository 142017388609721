$speed: 0.6s;

.spinner-background {
    position: fixed;
    inset: 0;
    z-index: 1;
    // background-color: rgba(255, 255, 255, 0.05);

    .spinner-wrapper {
        position: fixed;
        inset: 0;
        width: 80px;
        height: 80px;
        margin: auto;

        &>div {
            position: absolute;
            top: 33px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: var(--bs-primary);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);

            &:nth-child(1) {
                left: 8px;
                animation: fadeIn $speed infinite;
            }

            &:nth-child(2) {
                left: 8px;
                animation: moveLeft $speed infinite;
            }

            &:nth-child(3) {
                left: 32px;
                animation: moveLeft $speed infinite;
            }

            &:nth-child(4) {
                left: 56px;
                animation: fadeOut $speed infinite;
            }
        }
    }
}



@keyframes fadeIn {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes fadeOut {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes moveLeft {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}