.form-group {
    margin-bottom: 1rem;
}

// react-select dark theming
.react-select-container {
    .react-select__control {
        background-color: var(--bs-body-bg);
        border-color: var(--bs-modal-header-border-color);
        transition: none;
    }

    .react-select__menu {
        background-color: var(--bs-body-bg);
        border: 1px solid var(--bs-modal-header-border-color);
    }

    .react-select__option {
        background-color: var(--bs-body-bg);

        &:hover {
            background-color: var(--bs-body-bg);
            filter: brightness(85%);
            cursor: pointer;
        }
    }

    .react-select__indicator-separator {
        background-color: var(--bs-modal-header-border-color);
    }

    .react-select__placeholder,
    .react-select__single-value,
    .react-select__input {
        color: var(--bs-body-color) !important;
    }
}

.clickableIcon {
    cursor: pointer;
    margin-right: 8px;
}